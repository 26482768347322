.about-page h1 {
    font-size: 36px;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0em;
    text-align: center;
    color: #043F09;
    margin: 20px 0;
}



.team-members p {
    font-size: 18px;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0;
    text-align: center;
    margin-top: 20px;
}
.team-gallery {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.zonal-office-list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 30px 30px;
    /* Adjust the gap between cards */
}
.team-list {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
    justify-content: center;
}



.team-photo {
    width: 550px;
    height: 400px;
    /* display: inline-block; */
    display: flex;
    align-items: center;
    border-radius: 20px;
    overflow: hidden;
  }
.team-photo.medium{
    width: 360px;
    height: 360px;
}
  .team-photo.small {
    width: 250px;
    height: 250px;
  }
  .team-photo.medium img {
    object-position: center center;
  }
  
  .team-photo img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top center;
  }


p.team-info{
font-size: 22px;
font-weight: 600;
line-height: normal;
letter-spacing: 0;
text-align: center;
color: #043E09;
border-width: 0 3px 0  3px;
border-style: solid;
border-image-source: linear-gradient(180deg, #043F09 0%, rgba(4, 60, 5, 0.02) 80%);
border-image-slice: 1;
margin: 0 50px;
padding: 15px;
white-space: nowrap;

}

.team-info span{
font-size: 15px;
font-weight: 500;
line-height: normal;
letter-spacing: 0em;
text-align: center;
color: #000000;

}

p.team-info.medium{
    font-size: 18px;
    /* padding: 5px 10px; */
}



p.team-info.small{
    font-size: 16px;
    padding: 5px 10px;
}

.team-info.small span{
    font-size: 12px;
    white-space: normal;
}






@media screen and (max-width: 1025px) {
    .zonal-office-list {
        gap: 30px 15px;
    }    
    .zonal-office-card, .publication-card {
        margin: 0;
    }
    .team-list {
        grid-template-columns: repeat(3, 1fr);
    }
    
    .team-photo.small {
        width: 300px;
        height: 300px;
    }
}

@media screen and (max-width: 882px) {
    
    .zonal-office-list {
        gap: 30px 15px;
    } 
    .zonal-info h5 {
        font-size: 18px;
        padding: 10px;
    }
    
    .zonal-office-card, .publication-card {
        width: 260px;
    }
     .team-photo {
        width: 500px;
        height: 350px;
    }
    .team-photo.medium {
        width: 335px;
        height: 335px;
    }
    .team-photo.small {
        width: 230px;
        height: 230px;
    }
   
}

@media (max-width: 769px) {
    .zonal-office-list {
        justify-content: center;
    }
    .zonal-office-list {
        grid-template-columns: repeat(2, 1fr);
    }
    .about-page h1 {
        font-size: 30px;
    }
    .zonal-office-card, .publication-card {
        width: 330px;
        margin: 10px;
    }
    .team-list {
        grid-template-columns: repeat(2, 1fr);
    }
    .team-photo.small {
        width: 300px;
        height: 300px;
    }
   
    
}
@media screen and (max-width: 600px) {
    .zonal-office-card, .publication-card {
        width: 255px;
        margin: 0;
    }  
    .about-page h1 {
        font-size: 28px;
    }
    .team-members p.team-p {
        text-align: justify;
        width: 530px;
        margin: auto;
    }
    .team-photo {
        width: 450px;
        height: 300px;
    }
    .medium-size-card p.team-info {
        font-size: 18px;
        margin: 0 10px;
    }
    .medium-size-card .team-card{
        margin: 0 !important;
    }
    .team-photo.medium {
        width: 260px;
        height: 260px;
    }
    .team-list{
        max-width: min-content;
        gap: 20px 0;
    }
    .team-photo.small {
        width: 250px;
        height: 250px;
    }
}

@media screen and (max-width: 569px) {
    .team-members p.team-p {
        width: 510px;
    }
    .team-photo {
        width: 420px;
        height: 300px;
    }
    p.team-info {
        font-size: 20px;
        padding: 15px 5px;
    }
    .team-info span {
        font-size: 14px;
    }
    .team-photo.medium {
        width: 230px;
        height: 230px;
    }
    .team-photo.small {
        width: 230px;
        height: 230px;
    }
    p.team-info.small {
        font-size: 15px;
        padding: 5px 0px;
    }
}

@media screen and (max-width: 426px) {
    .zonal-office-list {
        grid-template-columns: repeat(1, 1fr);
    }
    .zonal-office-card, .publication-card {
        width: 330px;
    }
    .zonal-office-card{
        margin-bottom: 10px;
    }
    .about-page h1 {
        font-size: 24px;
    }
    .team-members p.team-p {
        width: 360px;
    }
    .team-list {
        grid-template-columns: repeat(1, 1fr);
    }
    .medium-size-card{
        flex-direction: column;
    }
    .team-photo {
        width: 370px;
        height: 300px;
    }
    .team-photo.medium, .team-photo.small {
        width: 335px;
        height: 335px;
    }
}

@media screen and (max-width: 376px) {
    /* .about-page h1 {
        font-size: 24px;
    } */
    .zonal-office-card, .publication-card {
        width: 300px;
    }
    .team-members p.team-p {
        width: 325px;
    }
   
    p.team-info {
        font-size: 18px;
        width: 250px;
    margin: 0 auto !important;
    white-space: normal;
    }
    .team-photo,.team-photo.medium, .team-photo.small {
        width: 300px;
        height: 300px;
    }
    .medium-size-card p.team-info {
        font-size: 18px;
        margin: 0 10px;
    }
}

@media screen and (max-width: 321px) {
    .zonal-office-card, .publication-card {
        width: 260px;
    }
    .about-page h1 {
        font-size: 18px;
    }
    
    .zonal-info h5 {
        font-size: 14px;
    }
    .team-members p.team-p {
        width: 270px;
    }
    .team-photo,.team-photo.medium, .team-photo.small {
        width: 250px;
        height: 250px;
    }
    p.team-info {
        font-size: 14px;
        width: 200px;
    }
}



