.hero-bg {
    --background-default: url('../../assets/landing-page.webp');
    --background-home: url('../../assets/landing-page.webp');
    --background-faq: url('../../assets/faq.webp');
    --background-registration: url('../../assets/registration-img.webp');
    --background-aboutus: url('../../assets/hero.webp');
    --background-publication: url('../../assets/publication1.webp');
    /* Define more custom backgrounds for other routes as needed */
    
    background: linear-gradient(180deg, rgba(1, 28, 3, 0.94), rgba(1, 28, 3, 0.94)), var(--background-default);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 550px;
    overflow-x: hidden;
}

.hero-bg h1 {
    font-size: 60px;
    font-weight: 600;
    line-height: 90px;
    letter-spacing: 0em;
    text-align: center;
    color: #ffffff;
}

.hero-bg h1 span {
    color: #E3AA24;
}




.zonal-office-card, .publication-card{
    width: 300px;
background-color: #FFFFFF ;
border-left: 1px solid #043F09;
box-shadow: 0px 4px 45px 0px #0000001A;
margin: 20px;
/* border-radius: 10px; */

}

.zonal-info h5{
    font-size: 22px;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0em;
    white-space: nowrap;
    color: #043F09;
    padding: 10px 20px;
    border-left: 4px solid #E3AA24;    
}

.zonal-info span{
    font-size: 13px;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0em;
    white-space: nowrap;
    
    
}

.zonal-info p{    
    font-size: 14px;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0em;
    /* white-space: nowrap; */
    
}
.team-list:nth-last-child(-n+3) .team-card {
    justify-self: center;
}

.publication-card-info p{
    font-size: 16px;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0em;
color: #043F09;    
}

.publication-card-info span{
font-size: 13px;
font-weight: 400;
line-height: normal;
letter-spacing: 0em;
color: #000000;
}

@media screen and (max-width: 882px) {
    .hero-bg h1 {
        font-size: 50px;
    }
}
@media  screen and (max-width: 822px) {
    .hero-bg h1 {
        font-size: 48px;
        line-height: normal;
    }
    
    .zonal-info p {
        white-space: nowrap;
    }

}

@media screen and (max-width: 769px) {
    .hero-bg h1 {
        font-size: 40px;
    }
  
}

@media  screen and (max-width: 600px) {
    .hero-bg h1 {
        font-size: 35px;
    }
    .hero-bg {
        padding-top: 40px !important;
        height: 400px;
    }
    .zonal-info p {
        line-height: normal !important;
    }
    .zonal-info p {
        white-space: normal;
    }
}

@media screen and (max-width: 567px) {}

@media screen and (max-width: 426px) {
    .hero-bg {
        height: 300px;
    }
    
    .hero-bg h1 {
        font-size: 25px;
        line-height: 40px;
    }
}

@media only screen and (max-width: 391px) {}

@media screen and (max-width: 376px) {
    .hero-bg {
        height: 100vh;
    }
    .hero-bg h1 {
        font-size: 20px;
        line-height: normal;
    }
}

@media screen and (max-width: 321px) {
    .hero-bg h1 {
        font-size: 18px;
        line-height: 30px;
    }
}