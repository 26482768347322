.header {
    position: fixed;
    background-color: #ffffff;
    top: 0;
    z-index: 899;
}

.nav-bar .logo img {
    width: 75px;
}

.nav-bar h1 {
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0em;
    color: #043E09;
    margin: 0 10px;
    white-space: nowrap;
}





#mobile {
    display: none;
    z-index: 1000;
}

#mobile .iconify {
    color: #043F09;
    align-items: center;
    /* background-color: #043E09; */
    /* z-index: 1000; */

}

.nav-bar .nav-links {
    display: flex;
    align-items: center;
}




.nav-bar .nav-links .nav-item,
.nav-bar .nav-links .nav-item.abt {
    text-decoration: none;
    font-size: 14px;
    font-weight: 500;
    line-height: normal;
    color: #000000;
    margin: 0 15px;
    letter-spacing: 0;
    box-shadow: none;
    background-color: transparent;
    border: 0;
    outline: 0;
    padding: 0;
    border-radius: 0;
    text-transform: capitalize;
    white-space: nowrap;
}

.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root:hover {
    color: inherit;
    text-decoration: none;
}

.nav-bar .nav-links a:hover,
.nav-bar .nav-item.abt:hover,
.nav-bar .nav-item.abt.active-link,
.nav-links a.active-link {
    font-weight: 600;
    border-bottom: 2px solid #043F09;
    color: #043F09;
    background-color: transparent;
}



.footer-nav {
    background: #1F1F20;
    color: #ffffff;
    padding: 0 15px;
}

.footer-nav h1 {
    font-size: 14px;
    font-weight: 700;
    line-height: 30px;
    letter-spacing: 0em;
    color: #ffffff;
    margin: 0 10px;
    white-space: nowrap;
}

.footer-nav img {
    width: 100px;
}

.footer-nav ul {
    list-style: none;
    padding: 0 !important;

}

.footer-links p {
    font-size: 15px;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0em;
    color: #F1F1F1;
    white-space: nowrap;
}

.footer-links {
    margin: 0 20px;
}

.footer-links li a,
.footer-links span {
    text-decoration: none;
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    /* color: #ffffff;     */

}


.footer-links li a:hover {
    color: #E3AA24;
    cursor: pointer;
}

.footer-links span.location {
    width: 300px;

}

.footer-nav .copyright {
    font-size: 12px;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0em;
    text-align: center;
}



@media screen and (max-width: 992px) {


    .nav-links {
        width: 100%;
        display: flex;
        flex-direction: column !important;
        align-items: center;
        justify-content: center !important;
        position: fixed;
        top: 80px;
        right: -1000px;
        height: 100vh;
        background-color: #043F09;
        /* top: 0; */
        transition: 0.3s ease-in-out;
        z-index: 1000;
        /* border-bottom: 1px solid #f2f2f2; */

    }

    .nav-links.active .nav-item {
        font-size: 25px;
        color: #FAFAFA;
        font-weight: 600;
        margin: 20px 0;
    }

    .nav-links.active {
        right: 0px;
    }

    #mobile {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 50px;
        position: absolute;
        /* Add this line to ensure the icon is not covered */

        right: 50px;
        /* Adjust this value to position the icon as needed */
    }

    .nav-bar .nav-links a:hover,
    .nav-links a.active-link {
        border-bottom: none;
    }

    .css-9tj150-MuiButton-endIcon>*:nth-of-type(1) {
        font-size: 40px;
    }

}

@media screen and (max-width: 822px) {
    .header {
        padding: 0.5rem 2rem !important;
    }

    .footer-nav img {
        width: 80px;
    }

    .footer-nav h1 {
        line-height: 20px;
    }
}

@media screen and (max-width: 769px) {
    .footer-links span.location {
        width: 255px;
        text-align: justify;
    }

    .my-footer-li {
        margin: 0 0.5rem !important;
    }

    .footer-links {
        margin: 0 10px;
    }
}

@media screen and (max-width: 600px) {
    .nav-links {
        top: 70px;
    }

    .nav-bar .logo img,
    .footer-nav img {
        width: 50px;
    }

    .footer-content {
        flex-direction: column;
        align-items: center !important;
    }

    .footer-content .links {
        margin-top: 25px;
    }

    .footer-links {
        margin: 0 20px;
    }

    .footer-nav img {
        width: 80px;
    }

    .footer-nav h1 {
        margin: 0 20px;
    }

}

@media screen and (max-width: 568px) {
    .footer-links {
        margin: 0 15px;
    }
}

@media screen and (max-width: 426px) {
    .nav-links {
        top: 60px;
    }

    .header {
        padding: 0.5rem 1rem !important;
    }

    #mobile {
        font-size: 40px;
        right: 30px;
    }


    .nav-bar h1 {
        font-size: 12px;
        line-height: normal;
    }

    .footer-content .links {
        /* display: grid !important;
        grid-template-columns: 1fr;
        justify-content: center !important; */
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}

@media screen and (max-width: 376px) {
    .nav-links.active .nav-item {
        font-size: 20px;
    }

    .footer-links {
        margin: 0 30px;
    }
}

@media screen and (max-width: 321px) {
    .nav-bar h1 {
        font-size: 10px;

    }

    .nav-bar .logo img {
        width: 35px;
    }

    .footer-nav img {
        width: 50px;
    }

}