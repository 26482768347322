.landing-page .about-us-section h1,
.registration-section h1,
.contact-us-section h1 {
    display: flex;
    align-items: center;
    border-left: 10px solid #E3AA24;
    font-size: 36px;
    font-weight: 700;
    line-height: 54px;
    letter-spacing: 0em;
    padding: 0 20px;
    text-align: center;
    color: #29410E;


}

.landing-page .about-us-section p {
    font-size: 18px;
    margin-top: 50px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0em;
    color: #000000;

}

.mission-vision-section {
    background: linear-gradient(90deg, rgba(1, 28, 3, 0.85), rgba(1, 28, 3, 0.85)), url('../../assets/mission.webp');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    height: 400px;
    color: #ffffff;
}

.mission,
.vision {
    margin: 0 30px;
}

.mission ul,
.vision p,
.registration-section ul {
    margin-top: 30px;
}

.mission li,
.vision p {
    font-size: 16px;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    width: 500px;

}
.mission ul{
    padding-left: 0;
}

.mission-vision-section h2 {
    font-size: 36px;
    font-weight: 600;
    line-height: 54px;
    letter-spacing: 0em;
    padding: 0 20px;
    border-left: 8px solid #E3AA24;

}

.registration-section li {
    font-size: 16px;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    margin-top: 10px;
    color: #000000;
}

.landing-page .btn.register-btn {
    width: 200px;
    padding: 10px;
    font-size: 20px;
    font-weight: 500;
    line-height: 36px;
    letter-spacing: 0em;
    text-align: center;
    background: #043F09;
    color: #ffffff;
    margin-top: 30px;


}

.contact-us-section {
    background-color: #F5F5F5;
}

.contact-form p {
    font-size: 18px;
    font-weight: 500;
    line-height: normal;
    color: #000000;
}

.contact-form .form-area input {
    width: 250px;
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 15px;
    font-weight: 500px;
    color: #444444;
    border: 1px solid #444444;
    outline: none;
    margin: 15px 0;
}

.contact-form input::placeholder,
.contact-form .textarea::placeholder {
    font-size: 14px;
    font-weight: 500;
    line-height: normal;

}

.contact-form .form-area input:focus {
    border: 1.5px solid #E3AA24;
}

.contact-form .textarea {
    width: 550px;
    padding: 10px;
    border-radius: 5px;
    font-size: 15px;
    font-weight: 500px;
    color: #444444;
    border: 1px solid #444444;
    outline: none;
    margin: 15px 0;
}

.vertical-hr {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* Adjust the height as needed */
    margin: 0 auto;
    /* Center the div */
}

.red-line,
.blue-line {
    width: 2px;
    flex-grow: 1;
}

.red-line {
    height: 80px;
    background-color: #043F09;
    /* Adjust color as needed */
}

.blue-line {
    height: 50px;
    background-color: #E3AA24;
    /* Adjust color as needed */
}


.contact-info {
    width: 500px;
    border-radius: 30px 0 30px 0;
    background-color: #043F09;
    color: #ffffff;
}

.contact-links p {
    font-size: 22px;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0em;
    text-align: center;
    margin: 50px auto;
}

.contact-links a {
    text-decoration: none;
    font-size: 18px;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0em;
    text-align: center;
    color: inherit;
}

.contact-links p span {
    /* width: 100px; */
    font-size: 14px;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0em;
    text-align: center;

}



@media screen and (max-width: 1025px) {

    .mission li,
    .vision p,
    .contact-form .textarea {
        width: 450px;
    }

    .mission,
    .vision {
        margin: 0 10px;
    }

    .contact-form .form-area input {
        width: 210px;
    }

    .contact-info {
        width: 410px;
    }

}

@media screen and (max-width: 991px) {
    .contact-links p {
        margin: 40px auto;
    }
}

@media screen and (max-width: 822px) {

    .landing-page section.my-5,
    .landing-page section.p-5 {
        margin: 2rem 0 !important;
        padding: 1rem 3rem !important;
    }

    .landing-page .about-us-section p {
        margin-top: 30px;
        text-align: justify;
    }

    .mission li,
    .vision p,
    .contact-form .textarea,
    .contact-info {
        width: 350px;
    }

    .contact-form .form-area input {
        width: 160px;
        text-overflow: ellipsis;
    }

    .form-area {
        justify-content: space-around !important;
    }
}

@media screen and (max-width: 769px) {

    .landing-page .about-us-section h1,
    .registration-section h1,
    .contact-us-section h1,
    .mission-vision-section h2 {
        font-size: 30px;
        padding: 0 15px;
    }

    .vision p {
        width: auto;
    }

    .contact-content {
        flex-direction: column !important;
        margin-top: 15px !important;
    }

    .vertical-hr {
        flex-direction: row;
        margin: 50px auto;
    }

    .red-line,
    .blue-line {
        height: 2px;
        width: 80px;
    }

    .contact-form {
        align-items: center;
        display: flex;
        flex-direction: column;
    }

    .contact-form .textarea,
    .contact-info {
        width: 600px;
    }

    .form-area {
        justify-content: space-between !important;
    }

    .contact-form .form-area input {
        width: 280px;
    }

    .landing-page section.p-5,
    .contact-us-section {
        padding: 2rem !important;
    }

    .landing-page .btn.register-btn {
        margin-top: 0;
    }

}

@media screen and (max-width: 600px) {
    

    .mission-vision-section {
        height: auto;
    }

    .mission-vision-content {
        flex-direction: column;
    }
.vision{
    margin-top: 20px;
}
    .landing-page .about-us-section h1,
    .registration-section h1,
    .contact-us-section h1,
    .mission-vision-section h2 {
        font-size: 26px;
        line-height: 30px;
         padding: 0 10px;
        border-left: 5px solid #E3AA24;
    }

   

    .mission li,
    .vision p {
        width: auto;
        text-align: justify;
    }

    .mission ul,
    .vision p,
    .registration-section ul, .landing-page .about-us-section p  {
        margin-top: 0px;
    }
    .contact-form .textarea, .contact-info {
        width: 500px;
    }
    .contact-form .form-area input {
        width: 230px;
    }
   
}

@media screen and (max-width: 567px) {}

@media screen and (max-width: 426px) {
    .landing-page .about-us-section h1,
    .registration-section h1,
    .contact-us-section h1,
    .mission-vision-section h2 {
        font-size: 22px;;
    }
    .registration-section li {
        text-align: justify;
    }
    .landing-page .btn.register-btn {
        width: auto;
    }
    .contact-form .textarea, .contact-info {
        width: 365px;
    }
    .contact-form .form-area input {
        width: 170px;
    }
    .red-line, .blue-line {
        width: 50px;
    }
    .mission-vision-section {
        padding: 0rem 1rem !important;
    }
}

@media screen and (max-width: 391px) {}

@media screen and (max-width: 376px) {
    .contact-us-section {
        padding: 2rem 1.5rem !important;
    }
    .contact-form .textarea, .contact-info {
        width: 330px;
    }
    .form-area{
        flex-direction: column;
    }
    .contact-form .form-area input {
        width: auto;
    }

    .contact-form .textarea {
       height: 150px;
    }
    .contact-links p {
        text-align: justify;
        margin: 20px auto;
        font-size: 20px;
    }

}

@media screen and (max-width: 321px) {
    .landing-page section.p-5 {
        padding: 0rem 1.5rem !important;
    }
    .landing-page .about-us-section p {
        font-size: 14px;
    }
    .landing-page .about-us-section h1, .registration-section h1, .contact-us-section h1, .mission-vision-section h2 {
        font-size: 20px;
    }
    .contact-form .textarea, .contact-info {
        width: 260px;
    }
    .contact-links a {
        font-size: 15px;
    }
}