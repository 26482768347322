.tab-header {
    display: flex;
    justify-content: space-around;
    border-bottom: 1px solid #E3AA24;
}

.tab {
    padding: 10px 20px;
    cursor: pointer;
    font-size: 22px;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0;
    color: #444444;

}

.tab.active {
    color: #043F09;
    border-bottom: 2px solid #043F09;
}


.multi-purpose-content p {
    font-size: 16px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0;

}
.multi-purpose-content h1.reg-title{
    border: 0;
font-size: 28px;
font-weight: 700;
line-height: normal;
margin-bottom: 3%;
letter-spacing: 0em;
text-align: left;
color: #043F09;
padding: 0;
white-space: nowrap;
}
.types{
    margin-bottom: 10%;
}
.types .single h1{
    border: none;
    padding: 0;
}

.types .single p{
   text-align: justify;
}
.multi-purpose-content h1 {
    display: flex;
    align-items: center;
    border-left: 6px solid #E3AA24;
    font-size: 20px;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0em;
    padding: 10px;
    text-align: center;
    color: #043F09;
    /* margin: 0 15px; */
}
.multi-purpose-content h2{
font-size: 20px;
font-weight: 700;
line-height: normal;
color: #043F09;
/* white-space: nowrap; */
}

.steps {
    display: flex;
    align-self: flex-start;
    justify-content: space-between;
}

.steps-card {
    padding: 10px 0;
    /* margin: 0 10px; */
    /* display: flex;
    flex-direction: column;
    justify-content: space-around; */
}


.multi-purpose-content .red-line {
    height: 30px; 
    background-color: #043F09; /* Adjust color as needed */
}

.multi-purpose-content  .blue-line {
    height: 30px; 
    background-color: #E3AA24; /* Adjust color as needed */
}

.multi-purpose-content .steps-card span {
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0em;
    color: #E3AA24;
    
}

.multi-purpose-content .steps-card h6 {
    font-size: 18px;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0em;
    color: #043F09;
    margin-top: 20px;
}

.multi-purpose-content .steps-card p {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: #000000;
    text-align: justify;
    width: 350px;   
    margin-top: 20px;
}
.multi-purpose-content .steps-card p a{
    text-decoration: none;
    color: #043F09;
    font-weight: 600;
}


.roman-list {
    list-style-type: none;
    counter-reset: roman-counter;
    padding-left: 0;
  }
  
  .roman-list li {
    counter-increment: roman-counter;
    margin-bottom: 10px;
  }
  
  .roman-list li::before {
    content: "(" counter(roman-counter, lower-roman) ") ";
    
  }

  .registration-requirement p, .download-form p{
    margin-top: 10px;
  }
.download-form span{
    color: #043F09;
    font-weight: 600;
    cursor: pointer;
}
  .btn.register-btn {
    width: 200px;
    padding: 10px;
    font-size: 16px;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0em;
    text-align: center;
    background: #043F09;
    color: #ffffff;
    margin-top: 10px;
}

.download-icon{
    font-size: 25px;
    margin: 0 5px;
    color: #ffffff;
}
@media screen and (max-width: 1025px) {
    .multi-purpose-content .steps-card p {
        width: 275px;
    }    
}

@media screen and (max-width: 882px) {
    .multi-purpose-content .steps-card p {
        width: 245px;
    }
    
}
@media screen and (max-width: 822px) {
    .multi-purpose-content .steps-card p {
        width: 210px;
    }
    .multi-purpose-content .vertical-hr {
        flex-direction: column;
        margin: 0 10px;
    }    
    .multi-purpose-content .red-line , .multi-purpose-content  .blue-line {
        width: 2px;
        
    }
}

@media screen and (max-width: 769px) {
    .multi-purpose.px-4 {
        padding-right: 1rem!important;
        padding-left: 1rem!important;
    }
    .multi-purpose-content .steps-card p {
        width: 205px;
    }
    .multi-purpose-content h1.reg-title {
        margin-bottom: 8%;
    }
}

@media screen and (max-width: 600px) {
    .steps {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .multi-purpose-content .steps-card p {
        width: auto;
        margin: 0 auto;
    }
    .multi-purpose-content .vertical-hr {
        display: none;
    }
    .registration .eligibility.mt-5 {
        margin-top: 30px !important;
    }
}

@media screen and (max-width: 568px) {
    .multi-purpose-content h1.reg-title {
        font-size: 25px;
        text-align: center;
    }
}

@media screen and (max-width: 426px) {
    .multi-purpose-content h1.reg-title {
        font-size: 20px;
    }
    .multi-purpose-content h1 {
        font-size: 18px;
    }
    .multi-purpose-content h2 {
        font-size: 16px;
        
    }
}

@media screen and (max-width: 376px) {
    .multi-purpose.px-4 {
        padding-right: 0.5rem!important;
        padding-left: 0.5rem!important;
    }
    .multi-purpose-content h1.reg-title {
        font-size: 18px;
    }
    .multi-purpose-content p {
        font-size: 14px;
        text-align: justify;
    }
    .multi-purpose-content h1 {
        font-size: 16px;
    }
    .multi-purpose-content h2 {
        font-size: 14px;
        text-align: center;
        
    }
}

@media screen and (max-width: 321px) {
    .multi-purpose-content h1.reg-title {
        font-size: 16px;
    }
    .multi-purpose-content h1 {
        font-size: 14px;
    }
    .btn.register-btn {
        width: auto;
        font-size: 14px;
    }
}