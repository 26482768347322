.publication-page .card-section{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 20px 50px;
}

.publication-page a{
    text-decoration: none;
}

.publication-page .publication-page-card {
    width: 300px;
    
    border-radius: 25px;
    box-shadow: 0px 4px 45px 3px #0000001A;
overflow: hidden;
    object-fit: contain;
    cursor: pointer;
    /* object-fit: contain; */
}


.publication-page-card img {
    display: block;
    width: 100%;
    max-height: auto;

}

.publication-page-card h2 {
    font-size: 14px;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0;
    color: #043F09;

}


.publication-page-card p {
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    color: #000000;
    text-decoration: underline;
    max-height: 5.4em; /* Adjust the height to control the number of lines */
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
}