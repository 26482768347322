@import url('https://fonts.cdnfonts.com/css/montserrat');

                
/* font-family: 'Montserrat', sans-serif; */


* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;  
    font-family: 'Montserrat', sans-serif;
  }
  body, html {
    scroll-behavior: smooth;
    overflow-x: hidden;
    background-color: #ffffff;
  }