
.accordion{
    width: 500px;
    padding: 10px;
border-radius: 10px;

}

.accordion.expand{
    border: 1px solid #E3AA24;
}

p.faq-p{
font-size: 18px;
font-weight: 400;
line-height: normal;
letter-spacing: 0em;
text-align: left;
color: #000000;
}

.faq .accordion-header {
   display: flex;
   align-items: center;
}
.faq-list{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 30px 20px;
}

.faq .faq-list .accordion-header p {
font-size: 16px;
font-weight: 500;
line-height: normal;
letter-spacing: 0;
color: #000000;
  margin: 0 10px;
}

.faq .accordion-header .faq-icons {
    font-size: 35px;
    color: #021C04;
}

.faq .accordion-content p {
font-size: 15px;
font-weight: 500;
line-height: 20px;
letter-spacing: 0em;
padding: 0 20px;
color: #000000;
text-align: justify;
margin-top: 20px;
}

@media screen and (max-width: 1025px) {
    .faq-list {
        gap: 30px 10px;
    }
    .accordion {
        width: 450px;
    }
}



@media screen and (max-width: 882px) {
 .faq-list{
    max-width: min-content;
    gap: 30px 0;
 }
 .accordion {
    width: 400px;
}
    
}


@media screen and (max-width: 769px) {
    .accordion {
        width: 350px;
    }
    .faq .faq-list .accordion-header p {
        font-size: 16px;
        text-align: justify;
    }
}

@media screen and (max-width: 600px) {
    .faq-list {
        grid-template-columns: repeat(1, 1fr);
    }
    .accordion {
    width: 500px;
    }
    p.faq-p {
        font-size: 16px;
        text-align: justify;
    }
}



@media screen and (max-width: 426px) {
    .accordion {
        width: 400px;
        }
        .faq .faq-list .accordion-header p, p.faq-p {
            font-size: 14px;
        }
        .faq .accordion-header .faq-icons {
            font-size: 30px;
            color: #021C04;
        }
}

@media screen and (max-width: 376px) {
    .accordion {
        width: 350px;
        }
        .faq .accordion-header .faq-icons {
            font-size: 25px;
            color: #021C04;
        }
}

@media screen and (max-width: 321px) {
    .faq .accordion-content p {
        font-size: 14px;
    }
    .accordion {
        width: 300px;
        }
}